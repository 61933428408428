<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Scam/Create</h4>
          <div>
            <router-link :to="{ ...previousRoute }">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-sm-12 col-md-3 border rounded pt-1">

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Scam Category <span class="text-danger">*</span></label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedScamCategory" class="multiselect-blue"
                                      :options="scamCategoryOptions" :close-on-select="true"
                                      placeholder="Select Scam Category"
                                      label="name" track-by="value" :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.post.postCategoryId">{{ errors.post.postCategoryId }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls select-tags-wrapper">
                      <label>Tags (optional)</label>
                      <VueMultiselect v-model="selectedTags" class="multiselect-blue"
                                      placeholder="Select Scam Tags" label="name" track-by="name"
                                      :options="tagOptions"
                                      :close-on-select="true"
                                      :clear-on-select="true"
                                      :multiple="true"
                                      :taggable="true"
                                      :show-labels="false"
                                      :max="4"
                                      :option-height="104"
                                      @tag="createTag"/>
                    </div>
                    <div class="text-danger" v-if="errors.post.postTagId">{{ errors.post.postTagId }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Is Featured?</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="selectedIsFeatured" type="checkbox" class="custom-control-input" checked
                                   id="blogIsFeatured">
                            <label class="custom-control-label mr-1" for="blogIsFeatured"></label>
                          </div>
                          <span class="font-medium-1">{{ selectedIsFeatured ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.isFeatured">{{ errors.post.isFeatured }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>Published Date</label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="w-100">
                        <DatePicker v-model="postScamData.publish_date" :update-on-input="true"
                                    :masks="{ input: ['DD MMMM YYYY'] }"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }">
                          <template v-slot="{ inputValue ,togglePopover  }">
                            <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                              <i class='bx bx-calendar mr-1'></i>
                              <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                            </div>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.publishedDate">{{ errors.user.publishedDate }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Is Published?</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="isPublished" type="checkbox" class="custom-control-input" checked
                                   id="postIsPublished">
                            <label class="custom-control-label mr-1" for="postIsPublished"></label>
                          </div>
                          <span class="font-medium-1">{{ isPublished ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.isPublished">{{ errors.post.isPublished }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Status</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="selectedStatus" type="checkbox" class="custom-control-input" checked
                                   id="blogStatus">
                            <label class="custom-control-label mr-1" for="blogStatus"></label>
                          </div>
                          <span class="font-medium-1">{{ selectedStatus ? "Active" : "Inactive" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.status">{{ errors.post.status }}</div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-8 border rounded ml-2 pt-1">
                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label for="title">Title *</label>
                      <input v-model="postScamData.title" type="text" class="form-control" id="title"
                             placeholder="Enter Scam Title">
                    </div>
                    <div v-if="errors.post.title" class="text-danger">{{ errors.post.title }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label for="title">Short Description/ Excerpt</label>
                      <input v-model="postScamData.excerpt" type="text" class="form-control" id="sort description"
                             placeholder="Short Description below 200 words">
                    </div>
                    <div v-if="errors.post.excerpt" class="text-danger">{{ errors.post.excerpt }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group text-editor">
                    <label>Body *</label>
                    <Editor
                        v-model="postScamData.body"
                        :init="editorConfig"
                        :api-key="tinyKey"
                    ></Editor>
                    <div class="text-danger" v-if="errors.post.body">{{ errors.post.body }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="accordion" id="accordionExample">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                  data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                  @click="toggleAccordion">
                            Add Meta Info
                            <i class="bx"
                               :class="{ 'bx-chevron-up': isAccordionOpen, 'bx-chevron-down': !isAccordionOpen }"></i>
                          </button>
                        </h2>
                      </div>

                      <div id="collapseOne" class="collapse hide" aria-labelledby="headingOne"
                           data-parent="#accordionExample">
                        <div class="card-body border">
                          <!-- Meta Tag Button -->
                          <div class="col-12 ml-1">
                            <button @click="addMetaTagRow" class="border rounded text-info">
                              <i class="bx bx-plus text-bold-600"></i>
                            </button>
                          </div>

                          <div v-if="metaTagRows.length > 0">
                            <div v-for="(metaTagRow, index) in metaTagRows" :key="index" class="col-12 pt-2">
                              <div class="row ml-0">
                                <div class="col-12 col-sm-4 col-md-4">
                                  <label>Name:</label>
                                  <VueMultiselect v-model="metaTagRow.selectedMeta"
                                                  :options="metaTagOptions"
                                                  :close-on-select="true" label="name" track-by="value"
                                                  :show-labels="false"
                                                  :allow-empty="false"
                                                  @select="updateMetaTagOption(index)"/>
                                </div>
                                <div class="col-12 col-sm-4 col-md-7">
                                  <label>Content:</label>
                                  <textarea v-model="metaTagRow.content" class="form-control"
                                            :placeholder="tagPlaceholder[index]"></textarea>

                                </div>
                                <div class="col-12 col-sm-4 col-md-1">
                                  <button @click="removeMetaTagRow(index)"
                                          class="bg-danger border rounded mt-2">
                                    <i class="bx bx-x text-bold-600"></i></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleBlog" type="button" class="btn btn-primary update-todo px-4">Create
                      Scam
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>

// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// core packages
import {mapActions, mapGetters} from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import {DatePicker} from 'v-calendar';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// css
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    Editor,
    DatePicker,

  },
  name: "ScamAlertCreate",

  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      tinyKey: process.env.VUE_APP_TINY_MCE_API_KEY,
      rowSize: 1,
      isAccordionOpen: false,
      selectedMetaTag: [],
      content: [],
      metaTagOptions: [
        {value: 'title', name: 'Title', contentPlaceholder: 'Enter meta title', $isDisabled: false},
        {
          value: 'description',
          name: 'Description',
          contentPlaceholder: 'Enter meta description, but not more than 250 Characters.',
          $isDisabled: false
        },
        {
          value: 'keywords',
          name: 'Keywords',
          contentPlaceholder: 'Enter meta keyword. please, separated by comma',
          $isDisabled: false
        },
        {value: 'author', name: 'Author', contentPlaceholder: 'Enter meta author', $isDisabled: false},
        {value: 'slug', name: 'Slug', contentPlaceholder: 'Enter meta slug', $isDisabled: false},
      ],
      metaTagRows: [],
      tagPlaceholder: [],
      selectedTags: [],
      isPublished: true,
      getScamCategoriesParams: {
        status: 1,
      },

      postScamData: {
        post_category_id: '',
        title: '',
        body: '',
        is_featured: 1,
        status: 1,
        excerpt: '',
        published: true,
        publish_date: '',
        tag_ids: []
      },
      editorConfig: {
        selector: '#basic-conf',
        width: '100%',
        height: 300,
        plugins: [
          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
          'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
          'table', 'emoticons', 'help'
        ],
        toolbar:
            'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
            'forecolor backcolor emoticons | help',
        menu: {
          favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
        },
        menubar: 'favs file edit view insert format tools table help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
      },
      postBlogBlogTagStoreListData: {
        blog_id: null,
        blog_tag_id: null,
      },

      selectedIsFeatured: false,
      selectedStatus: true,

      errors: {
        post: {
          postCategoryId: '',
          postTagId: '',
          title: '',
          body: '',
          isFeatured: '',
          status: '',
          excerpt: '',
          isPublished: '',
          publishedDate: ''
        }
      },

      selectedScamCategory: {
        value: '',
        name: 'None'
      },
      metaJson: []

    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authUser: 'appAuthLogin/authUser',
      scams: 'appScams/scams',
    }),
    redirectRouteName() {
      return this.can('post-view-any') ? 'appScamAlertList' : 'appScamAlertCreate';
    },

    scamCategoryOptions() {
      let scamCategories = this.$store.getters['appScamCategories/scamCategories'].map((scamCategory) => {
        let id = scamCategory.id;
        let name = scamCategory.name;
        return {value: id, name: name};
      });

      return [{value: '', name: 'None'}, ...scamCategories];
    },

    tagOptions() {
      return this.$store.getters['appTags/tags'].map(tag => {
        return {
          value: tag.id,
          name: tag.name,
        };
      });
    },
  },

  watch: {
    selectedScamCategory(selectedScamCategory) {
      this.postScamData.post_category_id = selectedScamCategory.value;
    },
    isPublished(isPublished) {
      this.postScamData.published = isPublished === true ? 1 : 0;
    },

    selectedIsFeatured(selectedIsFeatured) {
      this.postScamData.is_featured = selectedIsFeatured === true ? 1 : 0;
    },

    selectedStatus(selectedStatus) {
      this.postScamData.status = selectedStatus === true ? 1 : 0;
    },

  },

  methods: {
    ...mapActions({
      getScamCategories: 'appScamCategories/getScamCategories',
      getBlogTags: 'appBlogTags/getBlogTags',
      postBlogBlogTagStoreList: 'appBlogBlogTags/postBlogBlogTagStoreList',

      postScam: 'appScams/postScam',
      postTag: 'appTags/postTagOnList',
      getTags: 'appTags/getTags',

    }),
    addMetaTagRow() {
      if (this.metaTagRows.length === this.metaTagOptions.length) return;
      this.metaTagRows.push({selectedMeta: null, content: ''})
    },

    removeMetaTagRow(index) {
      const optionValue = this.metaTagRows[index].selectedMeta?.value;
      if (optionValue) {
        const optionIndex = this.metaTagOptions.findIndex(({value}) => value === optionValue);
        this.metaTagOptions[optionIndex] = {...this.metaTagOptions[optionIndex], $isDisabled: false};
      }
      this.metaTagRows.splice(index, 1);
    },

    updateMetaTagOption(index) {
      this.$nextTick(() => {
        // set placeholder from selected meta tag option
        this.tagPlaceholder[index] = this.metaTagRows[index].selectedMeta.contentPlaceholder;
        // get all meta tag rows value
        const currentValues = this.metaTagRows.map(row => row.selectedMeta?.value).filter(value => value);
        // set all meta option disable value false exclude currentValues
        this.metaTagOptions.forEach((row, index) => {
          this.metaTagOptions[index] = currentValues.includes(row.value) ? {
            ...this.metaTagOptions[index],
            $isDisabled: true
          } : {...this.metaTagOptions[index], $isDisabled: false};
        });
      });
    },

    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },

    async removeTag(removedTag, selectedTags) {
      console.log('Tag removed:', removedTag);
      console.log('Selected tags:', selectedTags);

      // You can perform additional actions or logging here
    },

    async getScamCategoryList() {
      await this.getScamCategories(this.getScamCategoriesParams);
    },


    async createSingleBlog() {
      this.postScamData.author = this.authUser?.id;
      const tag_ids = this.selectedTags.map(({value}) => value);
      const meta_tag_row = this.metaTagRows.map((row) => {
        return {
          name: row.selectedMeta.value,
          content: row.content
        }
      });

      this.postScamData.tag_ids = tag_ids;
      this.postScamData.meta = JSON.stringify(meta_tag_row);
      this.loader(true);
      await this.postScam(this.postScamData).then(async (response) => {
        this.loader(false);
        if (response.status === 201) {
          const toastObj = {
            message: 'Post created successful.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.post.postCategoryId = response?.errors?.post_category_id?.[0] ?? '';
        this.errors.post.postTagId = response?.errors?.blog_tag_id?.[0] ?? '';
        this.errors.post.title = response?.errors?.title?.[0] ?? '';
        this.errors.post.body = response?.errors?.body?.[0] ?? '';
        this.errors.post.isFeatured = response?.errors?.isFeatured?.[0] ?? '';
        this.errors.post.status = response?.errors?.status?.[0] ?? '';

        if (response.message) {
          this.showToastMessage(response);
        }

      });
    },

    async createTag(tag) {
      const data = {
        name: tag,
      }
      this.loader(true)
      await this.postTag(data).then(async response => {
        this.loader(false)
        if (response.status === 201) {
          this.selectedTags.push(this.tagOptions[0]);
          return;
        }
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    }

  },

  async mounted() {
    this.loader(true);
    await this.getScamCategoryList();
    this.loader(false);
  },
  async created() {
    await this.getTags();
  }

}
</script>


<style scoped>
.multiselect__tags:focus-within {

  border: 1px solid red !important;
  border-color: #5A8DEE;

}

.multiselect__tags {
  min-height: 50px !important;
  border-color: #DFE3E7;
}

.ql-editor {
  height: 70vh !important;
}


/* new css for ancortag */
.row {
  margin-left: 45px;
}

.btn-link {
  color: black;
}
</style>